import React from 'react';
import { Dialog } from 'primereact/dialog';
import ChatBody from './ChatBody';
import ChatInput from './ChatInput';
import { useSelector, useDispatch } from 'react-redux';
import { addUserMessage, sendMessage } from './chatSlice';
import './Chat.css'; // Import the central CSS file

const ChatDialog = ({ visible, onClose }) => {
  const dispatch = useDispatch();
  const { messages, isLoading, error } = useSelector((state) => state.chat);

  const handleSendMessage = (message) => {
    // Create a user message object
    const userMessage = {
      id: Date.now(),
      text: message,
      sender: 'user',
      timestamp: new Date(),
    };
    // Dispatch the addUserMessage action
    dispatch(addUserMessage(userMessage));
    // Dispatch the async thunk to send the message to the API
    dispatch(sendMessage(message));
  };

  return (
    <Dialog
      header="AI Powered Chat"
      visible={visible}
      onHide={onClose}
      modal
      style={{ margin: '24px', width: '100%', height: '100%' }}
      contentStyle={{ padding: '0', display: 'flex', flexDirection: 'column', height: '100%', borderBottomLeftRadius: '16px', borderBottomRightRadius: '16px' }}
      footer={null}
      draggable={false}
      resizable={false}
      className="chat-dialog"
    >
      {/* Custom Title at the top left */}
      {/* <div className="chat-dialog-title">
        <h3>AI Powered Chat</h3>
      </div> */}

      {/* Chat Body */}
      <ChatBody messages={messages} isLoading={isLoading} error={error} />

      {/* Chat Input */}
      <div className='chat-input-ai'>
        <ChatInput onSend={handleSendMessage} isLoading={isLoading} />
      </div>
    </Dialog>
  );
};

export default ChatDialog;
