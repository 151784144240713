// src/components/Chat/ChatBody.jsx

import React, { useEffect, useRef } from "react";
import { marked } from 'marked';
import "./Chat.css";

const ChatBody = ({ messages, isLoading, error }) => {
  // Create a ref to the end of the messages list
  const messagesEndRef = useRef(null);

  // useEffect to scroll to the bottom whenever messages, isLoading, or error changes
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, isLoading, error]);

  // Function to handle all scenarios and format the text appropriately
  function formatToMarkdown(rawText) {
    // Step 1: Split the text by lines for easier processing
    let lines = rawText.split("\n");

    // Step 2: Initialize variables to hold the formatted output and processing state
    let formattedText = "";
    let isCodeBlock = false;

    // Step 3: Helper function to detect a heading
    const isHeading = (line) => {
      return line.match(/^(Step|Instructions|Important|Process)/i);
    };

    // Step 4: Helper function to detect a numbered list
    const isNumberedList = (line) => {
      return line.match(/^\d+\.\s/);
    };

    // Step 5: Helper function to detect bullet points
    const isBulletPoint = (line) => {
      return line.match(/^-\s/);
    };

    // Step 6: Helper function to detect inline code or code blocks
    const isCode = (line) => {
      return line.includes("```") || line.includes("`");
    };

    // Step 7: Helper function to detect long paragraphs and split them
    const breakLongParagraphs = (line) => {
      const maxLength = 150;
      if (line.length > maxLength) {
        return line.match(/.{1,150}(\s|$)/g).join("\n\n");
      }
      return line;
    };

    // Step 8: Process each line
    lines.forEach((line) => {
      line = line.trim();

      // Handle code blocks
      if (line.includes("```")) {
        formattedText += `${line}\n`;
        isCodeBlock = !isCodeBlock;
      } else if (isCodeBlock) {
        formattedText += `${line}\n`;
      } else {
        // Check for headings and format as Markdown headings
        if (isHeading(line)) {
          formattedText += `## ${line}\n\n`;
        }
        // Check for numbered lists
        else if (isNumberedList(line)) {
          formattedText += `${line}\n`;
        }
        // Check for bullet points
        else if (isBulletPoint(line)) {
          formattedText += `${line}\n`;
        }
        // Handle inline code and other special formatting
        else if (isCode(line)) {
          formattedText += line.replace(/`([^`]+)`/g, "`$1`") + "\n";
        }
        // Handle important notices and key highlights by making them bold
        else if (line.toLowerCase().includes("important")) {
          formattedText += `**${line}**\n\n`;
        }
        // Handle regular paragraphs, breaking them if they are too long
        else {
          formattedText += breakLongParagraphs(line) + "\n\n";
        }
      }
    });

    // Step 9: Return the formatted Markdown text
    return formattedText.trim();
  }

  return (
    <div className="chat-body">
      {messages.map((message) => (
        <div
          key={message.id}
          className={`chat-message ${message.sender}`}
        >
          <div className="message-content">
            <p className="mb-1" dangerouslySetInnerHTML={{ __html: marked(message.text) }} ></p>
            <span className="chat-timestamp">
              {new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
            </span>
          </div>
        </div>
      ))}

      {isLoading && (
        <div className="chat-message assistant-message">
          <div className="message-content loading-content">
            <p>Loading...</p>
          </div>
        </div>
      )}

      {error && (
        <div className="chat-message assistant-message">
          <div className="message-content error-message">
            <p>{error}</p>
          </div>
        </div>
      )}

      {/* Dummy div to ensure auto-scroll */}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default ChatBody;
