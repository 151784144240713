import React from 'react';
import { Button } from 'primereact/button';
import 'primeicons/primeicons.css'; // Ensure primeicons are imported
import './Chat.css'

const ChatIconButton = ({ onClick }) => {
  return (
    <div className="chat-icon-button">
      <Button
        icon="pi pi-comments"
        className="p-button-rounded p-button-primary"
        onClick={onClick}
        aria-label="Open Chat"
      />
    </div>
  );
};

export default ChatIconButton;
