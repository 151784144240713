// src/components/Chat/ChatInput.jsx

import React, { useState } from 'react';
import { Button } from 'primereact/button';
import './Chat.css'; // Import the central CSS file

const ChatInput = ({ onSend }) => {
  const [message, setMessage] = useState('');

  const handleSend = () => {
    const trimmedMessage = message.trim();
    if (trimmedMessage) {
      onSend(trimmedMessage);
      setMessage('');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <div className="chat-input p-rel">
      <textarea
        className="chat-textarea form-control"
        placeholder="Type your message..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyPress={handleKeyPress}
        rows={1}
      />
      <Button
        icon="fi fi-rr-paper-plane l-h-1 v-align-middle"
        className="p-button-rounded btn btn-primary chat-ps-btn"
        onClick={handleSend}
        aria-label="Send Message"
      />
    </div>
  );
};

export default ChatInput;
