import MainContent from "./MainContent.jsx";

import logoBlack from "../../../assets/images/logo-black.png";
import logoWhite from "../../../assets/images/logo-white.png";
import { Link, Navigate, useLocation } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { useEffect, useRef, useState } from "react";
import ChatIconButton from "../../../features/chat/ChatIconButton.jsx";
import ChatDialog from "../../../features/chat/ChatDialog.jsx";
const MainLayout = () => {
  const handleEarlyAccess = () => {
    // localStorage.clear();
    Navigate("/get-early-access");
  };

  const ref = useRef(null);
  const location = useLocation();
  useEffect(() => {
    ref.current.continuousStart();
    setTimeout(() => {
      ref.current.complete();
    }, 100);
  }, [location]);

  const [isVisible, setIsVisible] = useState(false);

  // Button will be visible after user scrolls down 300px
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const [isChatOpen, setIsChatOpen] = useState(false);

  const handleChatOpen = () => {
    setIsChatOpen(true);
  };

  const handleChatClose = () => {
    setIsChatOpen(false);
  };

  return (
    <>
      <LoadingBar color="#305ff0" ref={ref} shadow={true} height={3} />
      <div className="sb-nav-fixed overall-body">
        <div id="layoutSidenav">
          <div id="layoutSidenav_content" className={"create-vch-header"}>
            <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark-tp ">
              <ul className="navbar-nav me-auto" id="megamenu">
                <li className="nav-item">
                  <div className="d-flex align-items-center">
                    <div className="pe-3-1rem header-beside-logo">
                      <img
                        src={logoBlack}
                        className="img-fluid"
                        alt="Kitaabh Logo Black"
                        width="138"
                      />
                    </div>
                  </div>
                </li>
              </ul>
              <ul className="navbar-nav ms-auto" id="megamenu">
                <li className="nav-item"></li>

                <li className="nav-item p-l-20 dropdown no-toggle-icon">
                  <a
                    className="dropdown-toggle"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <p className="top-header-text-names font-size-14 third-color mb-0">
                      vk
                    </p>
                  </a>
                </li>
              </ul>
            </nav>

            <main className="user-guide-pattern">
              <div className="">
                <MainContent />
              </div>
            </main>
          </div>
        </div>

        {/* Chat Icon Button */}
        <ChatIconButton onClick={handleChatOpen} />

        {/* Chat Dialog */}
        {isChatOpen && <ChatDialog visible={isChatOpen} onClose={handleChatClose} />}
      </div>
    </>
  );
};

export default MainLayout;
