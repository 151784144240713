import { configureStore } from "@reduxjs/toolkit";
import moduleReducer from "../features/modules/moduleSlice.js";
import chatReducer from "../features/chat/chatSlice.js";
import pageReducer from "../features/page/pageSlice.ts";
import topicReducer from "../features/topic/topicSlice.js";

const store = configureStore({
  reducer: {
    module: moduleReducer,
    chat: chatReducer,
    topic: topicReducer,
    page: pageReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
