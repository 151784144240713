// src/store/chatSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "../../axiosConfig";

// Replace with your actual API endpoint
const API_ENDPOINT = 'https://devapi.finsights.biz/kitaabhaidocappapi/search';

// Async thunk for sending messages to the API
export const sendMessage = createAsyncThunk(
  'chat/sendMessage',
  async (message, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_ENDPOINT}?query=${message}`);
      // Assuming the API returns a 'reply' field with the assistant's message
      return response.data;
    } catch (error) {
      console.error('API Error:', error);
      return rejectWithValue('Failed to fetch response from the server.');
    }
  }
);

const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    messages: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    addUserMessage: (state, action) => {
      state.messages.push(action.payload);
    },
    clearChat: (state) => {
      state.messages = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle pending state for sendMessage
      .addCase(sendMessage.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      // Handle fulfilled state for sendMessage
      .addCase(sendMessage.fulfilled, (state, action) => {
        const assistantMessage = {
          id: Date.now(),
          text: action.payload,
          sender: 'assistant',
          timestamp: new Date(),
        };
        state.messages.push(assistantMessage);
        state.isLoading = false;
      })
      // Handle rejected state for sendMessage
      .addCase(sendMessage.rejected, (state, action) => {
        const errorMessage = {
          id: Date.now(),
          text: action.payload || 'An error occurred.',
          sender: 'assistant',
          timestamp: new Date(),
        };
        state.messages.push(errorMessage);
        state.error = action.payload;
        state.isLoading = false;
      });
  },
});

export const { addUserMessage, clearChat } = chatSlice.actions;

export default chatSlice.reducer;
