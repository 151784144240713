import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk for creating a module
export const createModule = createAsyncThunk(
  'module/createModule',
  async (moduleData, { rejectWithValue }) => {
    try {
      const response = await axios.post('https://devapi.finsights.biz/kitaabhdocsapi/v1/projects', moduleData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const fetchModules = createAsyncThunk(
  'module/fetchModules', 
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('https://devapi.finsights.biz/kitaabhdocsapi/v1/projects'); 
      return response.data; 
    } catch (error) {
      return rejectWithValue(error.response.data); 
    }
  }
);

export const fetchModulesById = createAsyncThunk(
  'module/fetchModules', 
  async ({ moduleId}, { rejectWithValue }) => {
    try {
      const response = await axios.get(`https://devapi.finsights.biz/kitaabhdocsapi/v1/projects/${moduleId}`);
      return response.data; 
    } catch (error) {
      return rejectWithValue(error.response.data); 
    }
  }
);

export const updateModule = createAsyncThunk(
  'module/updateModule',
  async ({ moduleId, moduleData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`https://devapi.finsights.biz/kitaabhdocsapi/v1/projects/${moduleId}`, moduleData);
      return response.data;
    } catch (error) {
      console.error("Error updating module:", error.response ? error.response.data : error.message);
      return rejectWithValue(error.response?.data || 'Failed to update module');
    }
  }
);

export const deleteModule = createAsyncThunk(
  'module/deleteModule', 
  async (moduleId, { rejectWithValue }) => {
    try {
      await axios.delete(`https://devapi.finsights.biz/kitaabhdocsapi/v1/projects/${moduleId}`);
      return moduleId; 
    } catch (error) {
      return rejectWithValue(error.response.data); 
    }
  }
);


//----------------------CreateSlice---------------------------------

const moduleSlice = createSlice({
  name: 'module',
  initialState: {
    modules: [],
    loading: false,
    error: null,
  },
  reducers: {
    // Define synchronous actions here if needed
  },
  extraReducers: (builder) => {
    builder
    //------create--------
      .addCase(createModule.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createModule.fulfilled, (state, action) => {
        state.loading = false;
        state?.modules?.push(action.payload);
      })
      .addCase(createModule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to create module';
      })
      //--------get----------
      .addCase(fetchModules.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchModules.fulfilled, (state, action) => {
        state.loading = false;
        state.modules = action.payload; 
      })
      .addCase(fetchModules.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch modules';
      })
      //------update---------
      .addCase(updateModule.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateModule.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.modules.findIndex(module => module.id === action.payload.id);
        if (index !== -1) {
          state.modules[index] = action.payload;
        }
      })
      .addCase(updateModule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to update module';
      })

      //-------Delete-------
      .addCase(deleteModule.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteModule.fulfilled, (state, action) => {
        state.loading = false;
        state.modules = state.modules.filter(module => module.projectID !== action.payload);
      })
      .addCase(deleteModule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to delete module';
      });
  },
});


export default moduleSlice.reducer;
