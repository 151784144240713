import React from "react";


const HomePage = React.lazy(() => import("./components/layout/home/Home.jsx"));
const UserGuideLandingView = React.lazy(() => import("./components/layout/home/UserGuideView.jsx"));
const UserGuideCreate = React.lazy(() => import("./components/layout/create-user-guide/CreateUserGuide.jsx"));
const UserGuideList = React.lazy(() => import("./components/layout/create-user-guide/UserGuideList.jsx"));
const trainingHomePage = React.lazy(() => import("./components/layout/trainig-module/trainingHomePage.jsx"));
const PageRegistration = React.lazy(() => import("./features/page/PageRegistration.tsx"));



const routes = [

  { path: "/", component: trainingHomePage },
  { path: "/user-guide-view", component: UserGuideLandingView },
  { path: "/user-guide-create", component: UserGuideCreate },
  { path: "/user-guide-list", component: UserGuideList },
  { path: "/training-home", component: trainingHomePage },
  { path: "/page-registration", component: PageRegistration },
];

export default routes;
