import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
// import axios from "../../axiosConfig";
import axios from "axios";

// Define the Page interface
export interface Page {
  id?: number; // Assuming the backend assigns an ID
  name: string;
  description?: string;
  content: string; // Content from the editor
  raw_data: string
}

// Define the slice state
interface PageState {
  pages: Page[];
  loading: boolean;
  error: string | null;
}

// Initial state
const initialState: PageState = {
  pages: [],
  loading: false,
  error: null,
};

export const submitPage = createAsyncThunk<
  Page, // Return type
  Page, // Argument type
  { rejectValue: string } // Reject value type
>("page/submitPage", async (pageData, { rejectWithValue }) => {
  try {
    let payload = pageData;
    payload.raw_data = pageData.content
    const response = await axios.post<Page>("/page", pageData);
    return response.data;
  } catch (error: any) {
    // Handle errors appropriately
    if (error.response && error.response.data && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue("Network Error");
    }
  }
});

// Async thunk for fetching all pages
export const fetchPages = createAsyncThunk<
  Page[], // Return type
  void, // Argument type
  { rejectValue: string } // Reject value type
>("page/fetchPages", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get<Page[]>("/page/all");
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue("Network Error");
    }
  }
});

const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Submit Page
    builder
      .addCase(submitPage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(submitPage.fulfilled, (state, action: PayloadAction<Page>) => {
        state.loading = false;
        state.pages.push(action.payload);
      })
      .addCase(submitPage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Something went wrong";
      });

    // Fetch Pages
    builder
      .addCase(fetchPages.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPages.fulfilled, (state, action: PayloadAction<Page[]>) => {
        state.loading = false;
        state.pages = action.payload;
      })
      .addCase(fetchPages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch pages";
      });
  },
});

export default pageSlice.reducer;
