import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const createTopic = createAsyncThunk(
    'topic/createTopic',
    async (topicData, { rejectWithValue }) => {
      try {
        const response = await axios.post('https://devapi.finsights.biz/kitaabhdocsapi/v1/topics', topicData);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  
  
  export const fetchTopic = createAsyncThunk(
    'topic/fetchTopic', 
    async (_, { rejectWithValue }) => {
      try {
        const response = await axios.get('https://devapi.finsights.biz/kitaabhdocsapi/v1/topics'); 
        return response.data; 
      } catch (error) {
        return rejectWithValue(error.response.data); 
      }
    }
  );
  
  export const updateTopic = createAsyncThunk(
    'topic/updateTopic',
    async ({ topicId, topicData }, { rejectWithValue }) => {
      try {
        console.log("is this working//////")
        const response = await axios.put(`https://devapi.finsights.biz/kitaabhdocsapi/v1/topics/${topicId}`, topicData);
        return response.data;
      } catch (error) {
        console.error("Error updating Topic:", error.response ? error.response.data : error.message);
        return rejectWithValue(error.response?.data || 'Failed to update Topic');
      }
    }
  );
  
  export const deleteTopic = createAsyncThunk(
    'topic/deleteTopic', 
    async (topicId, { rejectWithValue }) => {
      try {
        await axios.delete(`https://devapi.finsights.biz/kitaabhdocsapi/v1/topics/${topicId}`);
        return topicId; 
      } catch (error) {
        return rejectWithValue(error.response.data); 
      }
    }
  );

const topicSlice = createSlice({
    name: 'topic',
    initialState: {
      topics: [],
      loading: false,
      error: null,
    },
    reducers: {
      // Define synchronous actions here if needed
      setLoading(state, action) {
        state.loading = action.payload;
      },
      setError(state, action) {
        state.error = action.payload;
      },
      setTopics(state, action) {
        state.topics = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder
      //--------create---------
        .addCase(createTopic.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(createTopic.fulfilled, (state, action) => {
          state.loading = false;
          state?.topics?.push(action.payload);
        })
        .addCase(createTopic.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload || 'Failed to create Topic';
        })
        //--------get-------
        .addCase(fetchTopic.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchTopic.fulfilled, (state, action) => {
          state.loading = false;
          state.topics = action.payload; 
        })
        .addCase(fetchTopic.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload || 'Failed to fetch Topic';
        })
  
        //---- update--------
        .addCase(updateTopic.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(updateTopic.fulfilled, (state, action) => {
          state.loading = false;
          const index = state?.topics?.findIndex(topic => topic.topicID === action.payload.topicID);
          if (index !== -1) {
            state.topics[index] = action.payload;
          }
        })
        .addCase(updateTopic.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload || 'Failed to update Topic';
        })
  
        //-------Delete-------
        .addCase(deleteTopic.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(deleteTopic.fulfilled, (state, action) => {
          state.loading = false;
          state.topics = state?.topics?.filter(topic => topic.topicID !== action.payload);
        })
        .addCase(deleteTopic.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload || 'Failed to delete Topic';
        });
    },
  });

  export default topicSlice.reducer;